@charset "utf-8";
/* CSS Document */

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* font-family: lato; */
}

.container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
}

.box {
    width: 270px;
    height: 400px;
    box-shadow: 0px 0px 7px 3px gray;
    text-align: center;
    margin: 25px auto;
    transition: all 0.5s;
   /* background-image: url('../images/background.jpg'); */
}
.logo {
    background-image: url('../images/logo.gif');
    display:block;
    width:200px;
    height:110px;
    margin:auto;
    background-size: contain;
    background-repeat: no-repeat;
}

.box:hover {
    filter: brightness(1.2);
}

h3 {
    margin-top: 10px;
    font-size: 16px;
    color: white;
}

.box-sub {
    width: 270px;
    height: 90px;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    font-size: 25px;
    font-weight: 500;
    color: white;
}

.box-sub-image {
    width: 270px;
    height: 63px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    margin-top: 0px;
}

.circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: linear-gradient(to top, blue, skyblue, white);
    box-shadow: 0px 0px 10px 2px blue;
    margin: 60px auto;
    line-height: 88px;
    text-align: center;
    font-size: 45px;
    font-weight: 400;
    color: white;
}

.bg1 {
    background-size: cover;
    width: 270px;
}

.x1 {
    background: linear-gradient(to top, gray, darkgrey, white);
    box-shadow: 0px 0px 10px 2px black;
}

.n1 {
    background: linear-gradient(to top, orange, darkorange, pink);
    box-shadow: 0px 0px 10px 2px orange;
}

.g1 {
    background: linear-gradient(to top, green, darkgreen, white);
    box-shadow: 0px 0px 10px 2px green;
}

.d1 {
    background: linear-gradient(to top, blue, darkblue, white);
    box-shadow: 0px 0px 10px 2px blue;
}

.searchInput {
    flex: 10;
    padding: 5px;
}

.searchForm {
    padding: 0 1rem;
    display: flex;
}

.searchButton {
    flex: 1;
    display: inline-block;
    border: none;
    background: #555;
    color: #fff;
    padding: 7px 20px;
    cursor: pointer;
}

.searchButton:hover {
    background: #666;
}
.numpadButton{
margin:5px;
background-color:white;
}
.buttonHideText span.p-button-text.p-c{
    display:none
}

.disabled-look-for-button {
    opacity: 0.6; 
}